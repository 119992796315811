'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/tag/page',
  //携带签客户 接口
  pageByTagUrl: '/account/contactTag/pageByTag',
  getPage(code, params) {
    return http.get('/account/tagType/page', { params, code });
  },
  getTagTreeList(code, params) {
    return http.get('/account/tag/getTagTree', { params, code });
  },

  add(code, data) {
    return http.post('/account/tagType', data, { code });
  },

  update(code, data) {
    return http.put('/account/tagType', data, { code });
  },

  delete(code, params) {
    return http.delete('/account/tag/deleteTag', { params, code });
  },

  getCityList(code, params) {
    return http.get(
      '/authority/dictionaryItem/hierarchyBycode?code=CHINA_AREA',
      {
        params,
        code,
      }
    );
  },
  getGroupRulesById(code, id) {
    return http.get(`/account/groupRule/getByConditionId?groupId=${id}`, {
      code,
    });
  },

  updateAutoSwitch(code, { id, autoSwitch }) {
    return http.put(
      `/account/tag/updateAutoSwitch/${id}/${autoSwitch}`,
      {},
      { code }
    );
  },
};
